import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4, H3 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import CategoryModal from "./CategoryModal";
import TableHeaderComp from "../../../../mycomponents/TableHeaderComp";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CategorySelector } from "./CategorySlice";
import { getcategory, deleteCategory } from "./api";
import { toast } from "react-toastify";
import BarLoader from "../../../../Layout/Loader/BarLoader";



const Category = () => {
  const dispatch = useDispatch();
  const { categoryData, isLoading, status } = useSelector(CategorySelector);
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [editData, setEditData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  console.log("categoryData", categoryData);

  useEffect(() => {
    dispatch(getcategory());
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const headingData = [
    { title: "DINING" },
    { title: "ESSENTIALS" },
    { title: "SERVICES" },
  ];



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  function deleteCB(data) {
    if (data.success) {
      toast.success("Deleted successfully");
    } else {
      toast.error("Something went wrong. Please try again later");
    }
  }

  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    if (conf) {
      dispatch(deleteCategory({ CB: deleteCB, id }));
    }
  };

  const existingViewRanks = [];

  const CategoryRow = categoryData?.result?.data
    ?.filter((val, i) => {
      return (
        val?.categoryName.includes(searchText) ||
        val?.parentKey.includes(searchText) ||
        val?.categoryName.toLowerCase().includes(searchText.toLowerCase()) ||
        val?.parentKey.toLowerCase().includes(searchText.toLowerCase()) ||
        val?.viewRank.toString().includes(searchText) ||
        val?.displayName.includes(searchText) ||
        val?.displayName.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    .map((val, i) => {
      return {
        index: i + 1,
        name: val.categoryName,
        parent_key: val.parentKey,
        displayName: val.displayName,
        rank: val.viewRank,
        action: (
          <Container className="d-flex btn-showcase">
            <Btn
              attrBtn={{
                color: "primary",
                outline: true,
                onClick: () => handleEdit(val),
                disabled: isLoading,
              }}
            >
              {"Edit"}
            </Btn>
            <Btn
              attrBtn={{
                color: "danger",
                outline: true,
                onClick: () => handleDelete(val._id),
                disabled: isLoading,
              }}
            >
              {"Delete"}
            </Btn>
          </Container>
        ),
      };
    });



  const CategoryColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Display Name</H6>,
      selector: (row) => row.displayName,
      sortable: true,
      center: true,
      wrap: true,
    },

    // {
    //   name: <H6>Essentials</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    // {
    //   name: <H6>Services</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    // {
    //   name: <H6>Dining</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    {
      name: <H6>Parent Key</H6>,
      selector: (row) => row.parent_key,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Rank</H6>,
      selector: (row) => row.rank,
      sortable: true,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <CategoryModal 
      isOpen={isAdd}
      ModalTitle={"Category | diningoptions"}
      toggler={handleAdd}
      />
      
      {isEdit && editData ? (
      <CategoryModal
        isOpen={isEdit}
        ModalTitle={"Category | diningoptions"}
        toggler={handleEdit}
        editData={editData}
        existingViewRanks={existingViewRanks}
      />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Category Configuration"
            title="Category Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                disabled: isLoading,
                onClick: handleAdd,
              }}
            >
              Add Category
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab align-items-baseline">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </Col>

              {/* <div className="social-tab p-t-0">
                <H3>{headingData[activeTab].title}</H3>
              </div> */}

              <CardBody className="p-t-15">
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={CategoryRow}
                    columns={CategoryColumns}
                    responsive={true}
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerPageRowsChange}
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                    // progressPending={loading}
                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Category;

import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { config } from "../../../../config";
import { addNewCategoryComponent, editCategoryComponent, getAllCategories } from "./categoriesApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";

export const DiningModal = ({
   isOpen,
   ModalTitle,
   editData,
   setEditData,
   toggler
}) => {
   const dispatch = useDispatch();
   const { categoryComponentsData, departmentsData, allCategories, error } = useSelector(categoriesSelector);
   const [componentName, setComponentName] = useState("Select Component");
   const [department, setDepartment] = useState("Select Department");
   const [isVisible, setIsVisible] = useState(true);
   const [inactive, setInactive] = useState(false);
   const [componentOptions, setComponentOptions] = useState([]);
   const [allData, setAllData] = useState([]);
   const [singleData, setSingleData] = useState([]);
   const [goTo, setGoTo] = useState(null);
   const [link, setLink] = useState(null);
   const [text, setText] = useState(null);
   const [textbox, setTextbox] = useState(null);
   const [type, setType] = useState("request");
   const [categoryOptions, setCategoryOptions] = useState([]);
   const [parentKey, setParentKey] = useState("Select Parent");

   const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: editData
      ? { ...editData, categoryName: "Dining" }
      : { categoryName: "Dining", type: "request" },
  });

   useEffect(() => {
     const componentNames = async () => {
       try {
         const res = await AXIOS_RESERVATION.get("getcomponent");
         console.log("API Response:", res.data);

         const filteredData = res.data?.result?.data || [];

         const viewTypes = filteredData
           .filter((val) => val.isVisible)
           .map((val) => val.viewType);

         setComponentOptions(viewTypes);
         setAllData(filteredData);
       } catch (error) {
         console.error("Error fetching component names:", error);
         setComponentOptions([]);
       }
     };

     componentNames();
   }, []);

   useEffect(() => {
    const categories = async () => {
      try {
        const res = await AXIOS_RESERVATION.get("getcategory");
        console.log("API Response:", res.data);
         
        const filteredData = res.data?.result?.data || [];
        const categoryNames = filteredData.map((val) => val.categoryName);
        setCategoryOptions(categoryNames);
      } catch (error) {
        console.error("Error fetching category names:", error);
        setCategoryOptions([]);
      }
    };
    categories();
  }, []);

   useEffect(() => {
     const selectedComponent = allData?.find(
       (val) => val.viewType == componentName
     );
     setSingleData(selectedComponent);
   }, [componentName]);

   useEffect(() => {
     if (editData) {
      console.log('editData>>>>>>>>>>>>>>>>>>> in Dining Modal',editData)  
       setComponentName(editData.viewType);
       setGoTo(editData.goTo);
       setLink(editData.link);
       setText(editData.text);
       setTextbox(editData.textbox);
       setParentKey(editData.goTo ?? "Select Parent");
       setValue("parentKey", editData.parentKey);

       if (
         editData.viewType == "submenu" ||
         editData.viewType == "webView" ||
         editData.viewType == "text" ||
         editData.viewType == "textbox"
       ) {
         setValue("amount", 0);
         setValue("description", "-");
         setValue("quantity", 0);
         setValue("maxQuantityPerRoom", 0);
       }
       if (editData.department) {
         setDepartment(
           departmentsData?.find((obj) => obj._id == editData.department)
             ?.departmentName ?? "Select Department"
         );
       }
       setIsVisible(editData.isVisible ?? true);
       setInactive(editData.inactive ?? false);

       setValue("categoryName", editData.categoryName);
       setValue("componentName", editData.viewType);
       setValue("name", editData.name);
       setValue("type", "request");
       if (editData.hasOwnProperty("goTo")) setValue("goTo", editData.goTo);
       if (editData.hasOwnProperty("amount"))
         setValue("amount", editData.amount);
       if (editData.hasOwnProperty("description"))
         setValue("description", editData.description);
       if (editData.hasOwnProperty("type")) setValue("type", editData.type);
       if (editData.hasOwnProperty("maxQuantityPerRoom"))
         setValue("maxQuantityPerRoom", editData.maxQuantityPerRoom);
       if (editData.hasOwnProperty("viewRank"))
         setValue("viewRank", editData.viewRank);
       if (editData.hasOwnProperty("department"))
         setValue("department", editData.department);
       if (editData.hasOwnProperty("gatewayCart"))
         setValue("gatewayCart", editData.gatewayCart);
       else setValue("gatewayCart", "");
     }
   }, [editData]);


   useEffect(() => {
      dispatch(getAllCategories());
   }, []);

   useEffect(() => {
      if(componentName !== "Select Component") setValue("componentName", componentName);
   }, [componentName]);

   useEffect(() => {
      if (department !== "Select Department") setValue("department", department);
   }, [department]);

   useEffect(() => {
      setValue("isVisible", isVisible);
   }, [isVisible]);

   useEffect(() => {
      setValue("inactive", inactive);
   }, [inactive]);

   let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
   departments = departments?.map((obj) => {
      obj.opt = obj.departmentName;
      return obj;
   });

   const addCB = (data) => {
      if(data.success) {
         return toast.success("Dining Added Successfully");
      } else {
         return toast.error(error ?? "Something went wrong. Please try again later.");
      }
   }

   const editCB = (data) => {
      if(data?.success) {
         toast.success("Edited Successfully");
      } else {
         toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
      }
   };

   const handleClose = () => {
      toggler();
      reset();
      setComponentName("Select Component");
      setDepartment("Select Department");
      setIsVisible(false);
      setValue("isVisible", false);
      setInactive(false);
      setValue("inactive", false);
      if(editData) setEditData(null);
   };

   const invalidValues = [undefined, null, ""];
   const onSubmit = (formValues) => {
      handleClose();
      if(formValues.componentName !== "text") {
         delete formValues.amount
         delete formValues.description;
         delete formValues.maxQuantityPerRoom;
         delete formValues.type;
      } else {
         if(invalidValues.includes(formValues.amount)) delete formValues.amount;
         else formValues.amount = parseInt(formValues.amount);

         if(invalidValues.includes(formValues.maxQuantityPerRoom)) delete formValues.maxQuantityPerRoom;
         else formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);
      }

      if(!["submenu", "submenuwithcart"].includes(formValues.componentName)) delete formValues.goTo;
      else {formValues.goTo = formValues.goTo.split(" ").join("");}

      if(invalidValues.includes(formValues.gatewayCart)) delete formValues.gatewayCart;
      if(invalidValues.includes(formValues.department)) delete formValues.department;

      formValues.viewRank = parseInt(formValues.viewRank);
      console.log('formValues>>>>>>>>>>>>>',formValues)
      delete formValues.departmentName;
      delete formValues.viewType;
      let { categoryName, componentName, isVisible, ...data } = formValues;
      console.log('formValues>>>>>>>>>>>>>',formValues)
      console.log('data>>>>>>>>>>>>>',data)
      data.hotelId = config.HOTELID;
  
      for(let key in data) {
        delete formValues[`${key}`];
      }
  
      if(data.department) data.department = departmentsData?.find((obj) => obj.departmentName == data.department)?._id;
  
      delete data._id;
      formValues.data = data;
      if(componentName == "submenu") {
        formValues.data.quantity = 0;
      }

      if(editData) {
         dispatch(editCategoryComponent({ ...formValues, id: editData._id, CB: editCB }));
      } else {
         if(componentName == "submenuwithcart") {
            formValues.isSubmenu = true;
            formValues.isDining = true;
         }
         dispatch(addNewCategoryComponent({ ...formValues, CB: addCB }));
      }
   };

   let existingViewRanks =
     categoryComponentsData?.component?.Dining?.map(obj => obj.viewRank) ??
     categoryComponentsData?.component?.dining?.map(obj => obj.viewRank) ??
     [];
      
   if(editData) existingViewRanks = existingViewRanks.filter(val => val != editData.viewRank);

   let existingCategories = allCategories?.map(obj => obj.categoryName) ?? [];
   if(editData) existingCategories = existingCategories.filter(val => val != editData.goTo);

   return (
     <>
       <CommonModal
         isOpen={isOpen}
         title={ModalTitle}
         toggler={handleClose}
         togglerSave={handleSubmit(onSubmit)}
       >
         <Container>
           <form className="needs-validation" noValidate="">
             <Row className="d-flex">
               <Col>
                 <FormGroup>
                   <Label>{"Category Name"}</Label>
                   <input
                     className="form-control"
                     type="text"
                     name="categoryName"
                     {...register("categoryName", { required: true })}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.categoryName && "Category Name is required"}
                   </span>
                 </FormGroup>
               </Col>
             </Row>

             <Row className="mb-3">
               <Col sm="6" col="9" className="btn-showcase">
                 <FormGroup>
                   <Label>{"Component Name"}</Label>
                   <MyDropdown
                     title={componentName}
                     setTitle={setComponentName}
                     data={componentOptions.map((val) => ({
                       opt: val,
                       value: val,
                     }))}
                     registerProps={register("componentName", {
                       required: true,
                     })}
                     disable={editData ? true : false}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.componentName && "Component Name is required"}
                   </span>
                 </FormGroup>
               </Col>
             </Row>

             {componentName == "webView" ? (
               <Row className="d-flex">
                 <Col>
                   <FormGroup>
                     <Label>{"title"}</Label>
                     <input
                       className="form-control"
                       type="text"
                       name="title"
                       {...register("title", {
                         required: true,
                       })}
                     />
                     <span style={{ color: "#ff5370" }}>
                       {errors.title?.type == "existing" && "Already exists"}
                       {errors.title?.type == "required" &&
                         "title is required for webview"}
                     </span>
                   </FormGroup>
                 </Col>
                 <Col>
                   <FormGroup>
                     <Label>{"subtitle"}</Label>
                     <input
                       className="form-control"
                       type="text"
                       name="subtitle"
                       {...register("subtitle")}
                     />                     
                   </FormGroup>
                 </Col>
               </Row>
             ) : null}

             {componentName == "webView" ? (
               <Col>
                 <FormGroup>
                   <Label>{"link"}</Label>
                   <input
                     className="form-control"
                     type="text"
                     name="link"
                     {...register("link", {
                       required: true,
                     })}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.link?.type == "existing" && "Already exists"}
                     {errors.link?.type == "required" &&
                       "link is required for submenu"}
                   </span>
                 </FormGroup>
               </Col>
             ) : null}
             {componentName == "submenu" ? (
               <Col>
                 <FormGroup>
                   <Label>{"Parent"}</Label>
                   <MyDropdown
                   title={parentKey}
                   setTitle={setParentKey}
                     className="form-control"
                    //  type="text"
                     name="goTo"
                     data={categoryOptions.map((val) => ({
                      opt: val,
                      value: val,
                    }))}
                    registerProps={register("goTo", {
                      required: true,
                    })}
                    disable={editData ? true : false}
                   />
                  {/* <span style={{ color: "#ff5370" }}>
                    {errors.goTo?.type == "existing" && "Already exists"}
                    {errors.goTo?.type == "required" &&
                      "goTo is required for submenu"}
                  </span> */}
                 </FormGroup>
               </Col>
             ) : null}

             <Row className="d-flex">
               <Col>
                 <FormGroup>
                   <Label>{"Item Name"}</Label>
                   <input
                     className="form-control"
                     type="text"
                     name="name"
                     {...register("name", { required: true })}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.name && "Item Name is required"}
                   </span>
                 </FormGroup>
               </Col>

               {!(
                 componentName === "submenu" ||
                 componentName === "webView" ||
                 componentName === "text" ||
                 componentName === "textbox"
               ) &&
                 !goTo &&
                 !link &&
                 !text &&
                 !textbox && (
                   <Col>
                     <FormGroup>
                       <Label>{"Amount"}</Label>
                       <input
                         className="form-control"
                         type="number"
                         name="amount"
                         {...register("amount", { required: true })}
                       />
                       <span style={{ color: "#ff5370" }}>
                         {errors.amount && "Amount is required"}
                       </span>
                     </FormGroup>
                   </Col>
                 )}
             </Row>
             {!(
               componentName === "submenu" ||
               componentName === "webView" ||
               componentName === "text" ||
               componentName === "textbox"
             ) &&
               !goTo &&
               !link &&
               !text &&
               !textbox && (
                 <Row>
                   <Col>
                     <FormGroup>
                       <Label>{"Description"}</Label>
                       <input
                         className="form-control"
                         type="text"
                         name="description"
                         {...register("description", { required: true })}
                       />
                       <span style={{ color: "#ff5370" }}>
                         {errors.description && "Description is required"}
                       </span>
                     </FormGroup>
                   </Col>
                 </Row>
               )}

             <Row>
               {!(
                 componentName === "submenu" ||
                 componentName === "webView" ||
                 componentName === "text" ||
                 componentName === "textbox"
               ) &&
                 !goTo &&
                 !link &&
                 !text &&
                 !textbox && (
                   <Col>
                     <FormGroup>
                       <Label>{"Quantity"}</Label>
                       <input
                         className="form-control"
                         type="number"
                         name="quantity"
                         {...register("quantity", { required: true })}
                       />
                       <span style={{ color: "#ff5370" }}>
                         {errors.quantity && "Quantity is required"}
                       </span>
                     </FormGroup>
                   </Col>
                 )}
               {!(
                 componentName === "submenu" ||
                 componentName === "webView" ||
                 componentName === "text" ||
                 componentName === "textbox"
               ) &&
                 !goTo &&
                 !link &&
                 !text &&
                 !textbox && (
                   <Col>
                     <FormGroup>
                       <Label>{"Max Quantity Per Room"}</Label>
                       <input
                         className="form-control"
                         type="number"
                         name="maxQuantityPerRoom"
                         {...register("maxQuantityPerRoom", { required: true })}
                       />
                       <span style={{ color: "#ff5370" }}>
                         {errors.maxQuantityPerRoom &&
                           "Max Quantity is required"}
                       </span>
                     </FormGroup>
                   </Col>
                 )}
             </Row>
             <Row>
               <Col sm="6">
                 <FormGroup>
                   <Label>{"Type"}</Label>
                   <input
                     className="form-control"
                     type="text"
                     name="type"
                     {...register("type", { required: true })}
                     disabled={true}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.type && "Type is required"}
                   </span>
                 </FormGroup>
               </Col>
               <Col>
                 <FormGroup>
                   <Label>{"View Rank"}</Label>
                   <input
                     className="form-control"
                     type="number"
                     name="viewRank"
                     {...register("viewRank", {
                       required: true,
                       min: { value: 1 },
                       validate: {
                         existing: (value) =>
                           !existingViewRanks.includes(parseInt(value)),
                       },
                     })}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.viewRank?.type == "min" &&
                       "View rank should be at least 1"}
                     {errors.viewRank?.type == "existing" &&
                       "This view rank already exists"}
                     {errors.viewRank?.type == "required" &&
                       "View rank is required"}
                   </span>
                 </FormGroup>
               </Col>
             </Row>

             <Row className="mb-3">
               <Col sm="6" col="9" className="btn-showcase">
                 <FormGroup>
                   <Label>{"Department"}</Label>
                   <MyDropdown
                     title={department}
                     setTitle={setDepartment}
                     data={departments}
                     registerProps={register("department", { required: true })}
                   />
                   <span style={{ color: "#ff5370" }}>
                     {errors.department && "Department is required"}
                   </span>
                 </FormGroup>
               </Col>
             </Row>

             <Row className="mb-2">
               <Col>
                 <div className="d-flex">
                   <Input
                     className="checkbox_animated"
                     id="inactive"
                     name="inactive"
                     type="checkbox"
                     checked={inactive}
                     onChange={(e) => setInactive(e.target.checked)}
                   />
                   <Label className="d-block" htmlFor="inactive">
                     Inactive ?
                   </Label>
                 </div>
               </Col>
               <Col>
                 <div className="d-flex">
                   <Input
                     className="checkbox_animated"
                     id="isVisible"
                     name="isVisible"
                     type="checkbox"
                     checked={isVisible}
                     onChange={(e) => setIsVisible(e.target.checked)}
                   />
                   <Label className="d-block" htmlFor="isVisible">
                     Is Visible ?
                   </Label>
                 </div>
               </Col>
             </Row>
           </form>
         </Container>
       </CommonModal>
     </>
   );
};

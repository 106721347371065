import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Btn, H6 } from "../../../../../AbstractElements";
import Increment from "../DisplayComponents/Increment";
import { useSelector } from "react-redux";
import { categoriesSelector } from "../categoriesSlice";
import { Container } from "reactstrap";
import BarLoader from "../../../../../Layout/Loader/BarLoader";
import { useDispatch } from "react-redux";
import { deleteCategoryComponent, getCategoryComponents } from "../categoriesApi";
import { toast } from "react-toastify";
import { EssentialsModal } from "../EssentialsModal";
import dragula from "react-dragula";
import { AXIOS_RESERVATION } from "../../../../../utils/axiosInterceptors";
let startIndex = null;
let endIndex = null;

const Essentials = ({ searchText }) => {
  const { categoryComponentsData, departmentsData, isLoading } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  
  console.log(
    "categoryComponentsData:",
    categoryComponentsData?.component?.Essentials
  );
  startIndex = (currentPage - 1) * perPage;
  endIndex = currentPage * perPage;
  console.log("startIndex:", startIndex, "endIndex:", endIndex);

  useEffect(() => {
    const draggableRefDiv = document.getElementsByClassName("rdt_TableBody");

    if (draggableRefDiv.length) {
      const containers = Array.from(draggableRefDiv);
      const drake = dragula(containers, {
        direction: "vertical",
        moves: (el, source, handle) => true,
      });

      drake.on("drop", async () => {
        const newItems = [];

        containers.forEach((container) => {
          Array.from(container.children).forEach((child) => {
            if (child.id) {
              // Ensure child has an ID
              newItems.push(child.id);
            }
          });
        });

        const updatedViewRanks = newItems.map((item, index) => {
          let indexOfCurrentRow = parseInt(item.split("-")[1]) + startIndex;

          let docAfterChange =
            categoryComponentsData?.component?.Essentials?.[indexOfCurrentRow];

          return {
            id: docAfterChange._id,
            viewRank: index + 1 + startIndex,
            name: docAfterChange.name,
          };
        });

        console.log("Updated View Ranks:", updatedViewRanks);

        await updateOrderInBackend(updatedViewRanks);

        dispatch(getCategoryComponents());
      });

      const preventTouchMove = (e) => e.preventDefault();
      containers.forEach((container) => {
        container.addEventListener("touchmove", preventTouchMove, {
          passive: false,
        });
      });

      return () => {
        drake.destroy(); // Cleanup on unmount
        containers.forEach((container) => {
          container.removeEventListener("touchmove", preventTouchMove);
        });
      };
    }
  }, [categoryComponentsData]);

  // Function to send updated order to the backend
  const updateOrderInBackend = async (updatedViewRanks) => {
    try {
      // Prepare data for batch update
      const response = await AXIOS_RESERVATION.post(
        "updateCategoryComponentOrder",
        { updatedViewRanks }
      );

      console.log("Data to be sent:", updatedViewRanks);
      if (response.status === 200) {
        console.log("Successfully updated orders:", response.data);
      } else {
        console.error("Failed to update orders:", response.statusText);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handlePerPageRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  function deleteCB(data) {
    if(data.success) {
      toast.success("Deleted successfully");
    } else {
      toast.error("Something went wrong. Please try again later");
    }
  }

  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };
  
  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure that you want to delete this item ?");
    if(conf) {
      dispatch(deleteCategoryComponent({ CB: deleteCB, id }));
    }
  };

  const existingViewRanks = [];

  let dataRows = categoryComponentsData?.component?.Essentials?.filter(
    (obj) => {
      // Ensure obj exists before accessing properties
      return (
        obj &&
        (obj.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.viewType?.toLowerCase().includes(searchText.toLowerCase()))
      );
    }
  )
    .map((obj) => {
      if (obj) {
        existingViewRanks.push(obj.viewRank); // Collect view ranks

        const departmentId = obj.department;
        const departmentName = departmentsData?.find(
          (depObj) => depObj._id === departmentId
        )?.departmentName;

        return {
          ...obj,
          departmentName: departmentName ?? obj.department, // Fallback to original department if not found
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(obj),
                  disabled: isLoading,
                }}
              >
                Edit
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(obj._id),
                  disabled: isLoading,
                }}
              >
                Delete
              </Btn>
            </Container>
          ),
        };
      }
      return null; // Return null for any undefined objects to avoid errors
    })
    .filter(Boolean); // Filter out any null values from mapping

  console.log("dataRows", dataRows); // For debugging purposes
  
  const dataColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Name
        </H6>
      ),
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "233px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Description
        </H6>
      ),
      selector: (row) => (row.description ? row.description : "-"),
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "213px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Department
        </H6>
      ),
      selector: (row) => (row.departmentName ? row.departmentName : "-"),
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "213px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Quantity
        </H6>
      ),
      selector: (row) => (row.quantity ? row.quantity : "0"),
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Max Quantity Per Room
        </H6>
      ),
      selector: (row) =>
        row.maxQuantityPerRoom ? row.maxQuantityPerRoom : "-",
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "250px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Amount
        </H6>
      ),
      selector: (row) => (row.amount ? row.amount : "0"),
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "173px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Component Name
        </H6>
      ),
      selector: (row) => row.viewType,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "203px",
    },
    {
      name: <H6>Component</H6>,
      selector: (row) =>
        row.viewType == "increment" ||
        row.viewType == "incrementwithprice" ||
        row.viewType == "submenu" ||
        row.viewType == "webView" ||
        row.viewType == "text" ||
        row.viewType == "textbox" ||
        row.viewType == "incrementwithimagewithoutrequest" ? (
          row.quantity ? (
            <Increment quantity={row.quantity} />
          ) : row.viewType == "textbox" ? (
            <input
              className="form-control"
              type="text"
              name="textbox"
              value={row.textbox}
            />
          ) : (
            "-"
          )
        ) : null,
      sortable: true,

      center: true,
      wrap: true,
      minWidth: "203px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          View Rank
        </H6>
      ),
      selector: (row) => row.viewRank,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "183px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          goTo
        </H6>
      ),
      selector: (row) => (row.goTo ? row.goTo : "-"),
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "183px",
    },
    {
      name: (
        <H6
          attrH6={{
            className: "m-l-15",
          }}
        >
          Link
        </H6>
      ),
      selector: (row) => (
        <a
          style={{ cursor: "pointer", color: "#ff0094" }}
          href
          onClick={(e) => {
            e.preventDefault();
            window.open(row.link, "_blank");
          }}
        >
          {row.link ? row.link : "-"}
        </a>
      ),
      center: true,
      wrap: true,
      minWidth: "303px",
    },
    {
      name: <H6>Title</H6>,
      selector: (row) => (row.viewType == "webView" ? row.title : "-"),
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: <H6>subTitle</H6>,
      selector: (row) => (row.viewType == "webView" ? row.subtitle : "-"),
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px"
    }
  ];
  
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      {isEdit ? (
        <EssentialsModal
          isOpen={isEdit}
          ModalTitle={"Edit Essential Item"}
          toggler={handleEdit}
          editData={editData}
          existingViewRanks={existingViewRanks}
        />
      ) : null}
      
      <div className="order-history table-responsive m-l-15">
        <DataTable
          pagination
          paginationComponentOptions={paginationOptions}
          data={dataRows}
          columns={dataColumns}
          responsive={true}
          progressPending={isLoading}
          progressComponent={<BarLoader />}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageRowsChange}
        />
      </div>
    </>
  );
}

export default Essentials;
import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { set, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { useDispatch } from "react-redux";
import {
  addNewCategoryComponent,
  editCategoryComponent,
} from "./categoriesApi";
import { config } from "../../../../config";
import { toast } from "react-toastify";
import { getcomponent } from "../Component/api";
import componentData from "../Component/Component"
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";

export const ServicesModal = ({ isOpen, ModalTitle, toggler, editData, setEditData, existingViewRanks }) => {
  const { departmentsData, error } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const [componentName, setComponentName] = useState("Select Component");
  const [categoryName, setCategoryName] = useState("Select Parent");
  const [department, setDepartment] = useState("Select Department");
  const [showTime, setShowTime] = useState(false);
  const [showLight, setShowLight] = useState(false);
  const [showMusic, setShowMusic] = useState(false);
  const [showTv, setShowTv] = useState(false);
  const [light, setLight] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [inactive, setInactive] = useState(false);
  const [componentOptions, setComponentOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [singleData, setSingleData] = useState([]);
  const [goTo, setGoTo] = useState(null);
  const [link, setLink] = useState(null);
  const [text, setText] = useState(null);
  const [textbox, setTextbox] = useState(null);
  const [parentKey, setParentKey] = useState("Select Parent");

  console.log('parentKey',parentKey)
  console.log('editData',editData)
  console.log('categoryOptions',categoryOptions)
  console.log('componentOptions',componentOptions)

    useEffect(() => {
      const componentNames = async () => {
        try {
          const res = await AXIOS_RESERVATION.get("getcomponent");
          console.log("API Response:", res.data);

          const filteredData = res.data?.result?.data || [];

          const viewTypes = filteredData
            .filter((val) => val.isVisible)
            .map((val) => val.viewType);

          setComponentOptions(viewTypes);
          setAllData(filteredData);
        } catch (error) {
          console.error("Error fetching component names:", error);
          setComponentOptions([]);
        }
      };

      componentNames();
    }, []);

    useEffect(() => {
      const categories = async () => {
        try {
          const res = await AXIOS_RESERVATION.get("getcategory");
          console.log("API Response:", res.data);
          const filteredData = res.data?.result?.data || [];
          const categoryNames = filteredData.map((val) => val.categoryName);
          setCategoryOptions(categoryNames);
        } catch (error) {
          console.error("Error fetching category names:", error);
          setCategoryOptions([]);
        }
      };
      categories();
    }, []);

    useEffect(() => {
      const selectedComponent = allData?.find(
        (val) => val.viewType == componentName
      );
      console.log("selectedComponent", selectedComponent);

      setSingleData(selectedComponent);
    }, [componentName]);

    useEffect(() => {
      const seletectedParentKey = allData?.find(
        (val) => val.categoryName == parentKey
      );
      console.log("seletectedParentKey", seletectedParentKey);

    }, [parentKey]);


    useEffect(() => {
      const seletectedParentKey = allData?.find(
        (val) => val.categoryName == parentKey
      );
      console.log("seletectedParentKey", seletectedParentKey);

    }, [parentKey]);


  useEffect(() => {
    if (editData) {
      setComponentName(editData.viewType);
     setCategoryOptions(categoryOptions);
      setGoTo(editData.goTo);
      setLink(editData.link);
      setText(editData.text);
      setTextbox(editData.textbox);
      setParentKey(editData.goTo ?? "Select Parent");
      setValue("parentKey", editData.parentKey);
      setValue("categoryName", editData.categoryName);
      setValue("componentName", editData.viewType);

      if (
        editData.viewType == "submenu" ||
        editData.viewType == "webView" ||
        editData.viewType == "text" ||
        editData.viewType == "textbox"
      ) {
        setValue("amount", 0);
        setValue("description", "-");
        setValue("quantity", 0);
        setValue("maxQuantityPerRoom", 0);
      }

      if(editData.parentKey) {
        setValue("parentKey", parentKey);
      }

      
      if (editData.department) {
        setDepartment(
          departmentsData?.find((obj) => obj._id == editData.department)
            ?.departmentName ?? "Select Department"
        );
      }
      setIsVisible(editData.isVisible ?? true);
      setInactive(editData.inactive ?? false);

      if (editData?.viewType == "wakeupcall") {
        setShowTime(editData.showTime);
        setShowLight(editData.showLight);
        setShowMusic(editData.showMusic);
        setShowTv(editData.showTv);
        setLight(editData.light);
      }
    }
}, [editData]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: editData
      ? { ...editData, categoryName: "Services" }
      : { categoryName: "Services", type: "request" }
  });

  useEffect(() => {
    if (componentName !== "Select Component")
      setValue("componentName", componentName);
  }, [componentName]);

  useEffect(() => {
    if(department !== "Select Department") setValue("department", department);
  }, [department]);

  useEffect(() => {
    setValue("showTime", showTime);
  }, [showTime]);

  useEffect(() => {
    setValue("showLight", showLight);
  }, [showLight]);

  useEffect(() => {
    setValue("showMusic", showMusic);
  }, [showMusic]);

  useEffect(() => {
    setValue("showTv", showTv);
  }, [showTv]);

  useEffect(() => {
    setValue("light", light);
  }, [light]);

  useEffect(() => {
    setValue("isVisible", isVisible);
  }, [isVisible]);

  useEffect(() => {
    setValue("inactive", inactive);
  }, [inactive]);

  if(editData) existingViewRanks = existingViewRanks.filter(val => val != editData.viewRank);

  let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
  departments = departments?.map((obj) => {
    obj.opt = obj.departmentName;
    return obj;
  });

  const addCB = (data) => {
    if(data.success) {
      return toast.success("Service Added Successfully");
    } else {
      return toast.error(error ?? "Something went wrong. Please try again later.");
    }
  }

  const editCB = (data) => {
    if(data?.success) {
      toast.success("Edited Successfully");
    } else {
      toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
    }
  };

  const handleClose = () => {
    toggler();
    reset();
    setComponentName("increment");
    setValue("componentName", "increment");
    setDepartment("Select Department");
    setShowTime(false);
    setValue("showTime", showTime);
    setShowLight(false);
    setValue("showLight", showLight);
    setShowMusic(false);
    setValue("showMusic", showMusic);
    setShowTv(false);
    setValue("showTv", showTv);
    setLight(false);
    setValue("light", light);
    setIsVisible(true);
    setValue("isVisible", true);
    setInactive(false);
    setValue("inactive", false);
    if(editData) setEditData(null);
  };

  const onSubmit = (formValues) => {
    handleClose();

    if(formValues.componentName != "wakeupcall") {
      delete formValues.showTime;
      delete formValues.showLight;
      delete formValues.showMusic;
      delete formValues.showTv;
      delete formValues.light;
    }

    formValues.amount = parseInt(formValues.amount);

    if([undefined, null, ""].includes(formValues.quantity)) delete formValues.quantity;
    else formValues.quantity = parseInt(formValues.quantity);

    if([undefined, null, ""].includes(formValues.maxQuantityPerRoom)) delete formValues.maxQuantityPerRoom;
    else formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);

    formValues.viewRank = parseInt(formValues.viewRank);

    delete formValues.departmentName;
    delete formValues.viewType;
    delete formValues.action;

    let { categoryName, componentName, isVisible, ...data } = formValues;
    data.hotelId = config.HOTELID;

    for(let key in data) {
      delete formValues[`${key}`];
    }

    data.department = departmentsData?.find((obj) => obj.departmentName == data.department)?._id;

    delete data._id;
    formValues.data = data;
    formValues.data.goTo = parentKey;
    console.log("formValues in servicemodal>>>>>>>>>", formValues);

    if(editData) {
      dispatch(
        editCategoryComponent({ ...formValues, id: editData._id, CB: editCB })
      );
    } else {
      dispatch(
        addNewCategoryComponent({ ...formValues, CB: addCB })
      );
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={handleClose}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container>
          <form className="needs-validation" noValidate="">
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Category Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="categoryName"
                    {...register("categoryName", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.categoryName && "Category Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Component Name"}</Label>
                  <MyDropdown
                    title={componentName}
                    setTitle={setComponentName}
                    data={componentOptions.map((val) => ({
                      opt: val,
                      value: val,
                    }))}
                    registerProps={register("componentName", {
                      required: true,
                    })}
                    disable={editData ? true : false}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.componentName && "Component Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            {componentName == "webView" ? (
              <Row className="d-flex">
                <Col>
                  <FormGroup>
                    <Label>{"title"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      {...register("title", {
                        required: true,
                      })}
                    />
                    <span style={{ color: "#ff5370" }}>
                      {errors.title?.type == "existing" && "Already exists"}
                      {errors.title?.type == "required" &&
                        "title is required for webview"}
                    </span>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>{"subtitle"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="subtitle"
                      {...register("subtitle")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            {componentName == "webView" ? (
              <Col>
                <FormGroup>
                  <Label>{"link"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="link"
                    {...register("link", {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.link?.type == "existing" && "Already exists"}
                    {errors.link?.type == "required" &&
                      "link is required for submenu"}
                  </span>
                </FormGroup>
              </Col>
            ) : null}

            <Row className="d-flex">

            {componentName == "submenu" ? (
              <Col>
                <FormGroup>
                  <Label>{"Parent"}</Label>
                  <MyDropdown 
                  title={parentKey}
                  setTitle={setParentKey}
                  name="goTo"
                  data={categoryOptions.map((val) => ({
                    opt: val,
                    value: val,
                  }))}
                  registerProps={register("goTo", {
                    required: true,
                  })}
                  disable={editData ? true : false}

                  />
                   <span style={{ color: "#ff5370" }}>
                    {errors.goTo && "goTo Key is required"}
                  </span>
                </FormGroup>
              </Col>
            ) : null}

              <Col>
                <FormGroup>
                  <Label>{"Item Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Item Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex">
              {!(
                componentName === "submenu" ||
                componentName === "webView" ||
                componentName === "text" ||
                componentName === "textbox"
              ) &&
                !goTo &&
                !link &&
                !text &&
                !textbox && (
                  <Col>
                    <FormGroup>
                      <Label>{"Amount"}</Label>
                      <input
                        className="form-control"
                        type="number"
                        name="amount"
                        {...register("amount", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.amount && "Amount is required"}
                      </span>
                    </FormGroup>
                  </Col>
                )}
            </Row>

            {!(
              componentName === "submenu" ||
              componentName === "webView" ||
              componentName === "text" ||
              componentName === "textbox"
            ) &&
              !goTo &&
              !text &&
              !textbox &&
              !link && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{"Description"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        {...register("description", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.description && "Description is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              )}

            {!editData || editData.hasOwnProperty("actionType") ? (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{"Action Type"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="actionType"
                      {...register("actionType")}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            <Row>
              {!(
                componentName === "submenu" ||
                componentName === "webView" ||
                componentName === "text" ||
                componentName === "textbox"
              ) &&
                !goTo &&
                !text &&
                !textbox &&
                !link && (
                  <Col>
                    <FormGroup>
                      <Label>{"Quantity"}</Label>
                      <input
                        className="form-control"
                        type="number"
                        name="quantity"
                        {...register("quantity", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.quantity && "Quantity is required"}
                      </span>
                    </FormGroup>
                  </Col>
                )}
              {!(
                componentName === "submenu" ||
                componentName === "webView" ||
                componentName === "text" ||
                componentName === "textbox"
              ) &&
                !goTo &&
                !text &&
                !textbox &&
                !link && (
                  <Col>
                    <FormGroup>
                      <Label>{"Max Quantity Per Room"}</Label>
                      <input
                        className="form-control"
                        type="number"
                        name="maxQuantityPerRoom"
                        {...register("maxQuantityPerRoom", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.maxQuantityPerRoom &&
                          "Max Quantity is required"}
                      </span>
                    </FormGroup>
                  </Col>
                )}
            </Row>

            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>{"Type"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="type"
                    {...register("type", { required: true })}
                    disabled={true}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.type && "Type is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"View Rank"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="viewRank"
                    {...register("viewRank", {
                      required: true,
                      min: { value: 1 },
                      validate: {
                        existing: (value) =>
                          !existingViewRanks.includes(parseInt(value)),
                      },
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.viewRank?.type == "min" &&
                      "View rank should be at least 1"}
                    {errors.viewRank?.type == "existing" &&
                      "This view rank already exists"}
                    {errors.viewRank?.type == "required" &&
                      "View rank is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Department"}</Label>
                  <MyDropdown
                    title={department}
                    setTitle={setDepartment}
                    data={departments}
                    registerProps={register("department", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.department && "Department is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            {componentName == "wakeupcall" ? (
              <Row className="mb-2">
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showTime"
                      name="showTime"
                      type="checkbox"
                      checked={showTime}
                      onChange={(e) => setShowTime(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showTime">
                      Show Time
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showLight"
                      name="showLight"
                      type="checkbox"
                      checked={showLight}
                      onChange={(e) => setShowLight(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showLight">
                      Show Light
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showMusic"
                      name="showMusic"
                      type="checkbox"
                      checked={showMusic}
                      onChange={(e) => setShowMusic(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showMusic">
                      Show Music
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showTv"
                      name="showTv"
                      type="checkbox"
                      checked={showTv}
                      onChange={(e) => setShowTv(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showTv">
                      Show TV
                    </Label>
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="light"
                      name="light"
                      type="checkbox"
                      checked={light}
                      onChange={(e) => setLight(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="light">
                      Light
                    </Label>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="inactive"
                    name="inactive"
                    type="checkbox"
                    checked={inactive}
                    onChange={(e) => setInactive(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="inactive">
                    Inactive ?
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isVisible"
                    name="isVisible"
                    type="checkbox"
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isVisible">
                    Is Visible ?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../categoriesSlice';
import { DiningCategoryDetails } from './DetailsComponents/DiningCategoryDetails';

const DiningCategory = ({ searchText, setShowSearchInput }) => {
  const { categoryComponentsData, departmentsData } = useSelector(categoriesSelector);
  let diningTabs = JSON.parse(
    JSON.stringify(
      categoryComponentsData?.component?.Dining ??
        categoryComponentsData?.component?.dining ??
        []
    )
  );

  // Filter and map dining tabs with department names
  diningTabs = diningTabs
    .filter(
      (obj) =>
        obj &&
        obj.name &&
        obj.name.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((obj) => {
      const department = departmentsData?.find(
        (depObj) => depObj._id === obj.department
      );
      obj.departmentName = department ? department.departmentName : null; // Assign departmentName or null if not found
      return obj;
    });

  console.log("diningTabs", diningTabs);
  return (
    <Fragment>
      <DiningCategoryDetails
        diningTabs={diningTabs}
        searchText={searchText}
        setShowSearchInput={setShowSearchInput}
      />
    </Fragment>
  );
}

export default DiningCategory;
const { createSlice } = require("@reduxjs/toolkit");
const {
  getcomponent,
  addnewcomponent,
  editcomponent,
  removecomponent,
} = require("./api");

const initialState = {
  componentData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const componentDataSlice = createSlice({
  initialState,
  name: "getcomponent",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getcomponent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getcomponent.fulfilled, (state, action) => {
        console.log("component fulfilled:", action.payload);
        state.isLoading = false;
        state.componentData = action.payload;
      })
      .addCase(getcomponent.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload && payload.status;
      });

    // // CASE FOR ADD component
    // .addCase(addnewcomponent.pending, (state) => {
    //     state.isLoading = true;
    // })
    // .addCase(addnewcomponent.fulfilled, (state, { payload }) => {
    //     state.isLoading = false;
    // })
    // .addCase(addnewcomponent.rejected, (state, { payload }) => {
    //     state.isLoading = false;
    //     state.status = payload && payload.status;
    // })

    // // CASE FOR EDIT component
    // .addCase(editcomponent.pending, (state) => {
    //     state.isLoading = true;
    // })
    // .addCase(editcomponent.fulfilled, (state, { payload }) => {
    //     state.isLoading = false;
    // })
    // .addCase(editcomponent.rejected, (state, { payload }) => {
    //     state.isLoading = false;
    //     state.status = payload.status;
    // })

    // // CASE FOR REMOVE component
    // .addCase(removecomponent.pending, (state) => {
    //     state.isLoading = true;
    // })
    // .addCase(removecomponent.fulfilled, (state, { payload }) => {
    //     state.isLoading = false;
    // })
    // .addCase(removecomponent.rejected, (state, { payload }) => {
    //     state.isLoading = false;
    //     state.status = payload.status;
    // });
  },
});

export default componentDataSlice.reducer;
export const { setStatus } = componentDataSlice.actions;
export const componentDataSelector = (state) => state.getcomponent;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";

export const getCategoryComponents = createAsyncThunk(
  "categories/getCategoryComponentws3",
  async (state, { rejectWithValue }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get("getCategoryComponentws3");
      return data;
    } catch (err) {
      console.error("Error occured in getCategoryComponents API call: ", err);
      return rejectWithValue(err.message);
    }
  }
);

export const getAllDepartments = createAsyncThunk(
  "categories/getAllDepartments",
  async (state, { rejectWithValue }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get("getAllDepartments");
      return data;
    } catch (err) {
      console.error("Error occured in getAllDepartments API call: ", err);
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async (state, { rejectWithValue }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get("getAllCategories");
      console.log('data:', data);
      return data;
    } catch (err) {
      console.error("Error occured in getAllCategories API call: ", err);
      return rejectWithValue(err.message);
    }
  }
)

export const addNewCategoryComponent = createAsyncThunk(
  "categories/addNewCategoryComponent",
  async (state, { rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    try {
      let { data } = await AXIOS_RESERVATION.post("addNewCategoryComponent", others);
      CB && CB(data);
      if(data.success) {
        dispatch(getCategoryComponents());
        return data;
      }
    } catch (err) {
      console.error("Error in addNewCategoryComponent: ", err);
      return rejectWithValue(err.response?.data?.message ?? err.message ?? "Something went wrong. Please try again later.");
    }
  }
);

export const editCategoryComponent = createAsyncThunk(
  "categories/editCategoryComponent",
  async (state, { rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    try {
      let { data } = await AXIOS_RESERVATION.patch("editCategoryComponent", others);
      CB && CB(data);
      if(data.success) {
        dispatch(getCategoryComponents());
        return data;
      }
    } catch (err) {
      console.error("Error in editCategoryComponent: ", err);
      err.success = false;
      CB && CB(err);
    }
  }
);

export const deleteCategoryComponent = createAsyncThunk(
  "categories/deleteCategoryComponent",
  async (state, { dispatch }) => {
    let { CB, id } = state;
    try {
      let { data } = await AXIOS_RESERVATION.delete(`deleteCategoryComponent/${id}`);
      dispatch(getCategoryComponents());
      CB && CB(data);
    } catch (err) {
      console.error("Error in deleteCategoryComponent: ", err);
      CB && CB(err);
    }
  }
);
import React, { useState, useMemo, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CategorySelector } from "./CategorySlice";
import { addNewCategory, editCategory } from "./api";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

const CategoryModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const dispatch = useDispatch();
  const { categoryData, isLoading, statusk, error } =
    useSelector(CategorySelector);

  const [title, setTitle] = useState("Select Parent");
  const [categoryName, setCategoryName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [viewRank, setViewRank] = useState("");
  const [parentkey, setParentKey] = useState("Select Parent");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [singleData, setSingleData] = useState([]);
  console.log("viewRank", viewRank);

  console.log("categoryName", categoryName);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    const categories = async () => {
      try {
        const res = await AXIOS_RESERVATION.get("getcategory");
        console.log("API Response:", res.data);

        const filteredData = res.data?.result?.data || [];

        const categoryNames = filteredData.map((val) => val.categoryName);
        console.log(categoryNames);
        setCategoryOptions(categoryNames);
      } catch (error) {
        console.error("Error fetching category names:", error);
        setCategoryOptions([]);
      }
    };
    categories();
  }, []);

  useEffect(() => {
    if (editData) {
      setCategoryName(editData.categoryName);
      setParentKey(editData.parentKey);
      setDisplayName(editData.displayName);
      setViewRank(editData.viewRank);
      setValue("categoryName", editData.categoryName);
      setValue("displayName", editData.displayName);
      setValue("viewRank", editData.viewRank);
    }
  }, [editData]);

  useEffect(() => {
    if (categoryName !== "") setValue("categoryName", categoryName);
  }, [categoryName]);

  useEffect(() => {
    if (displayName !== "") setValue("displayName", displayName);
  }, [displayName]);

  useEffect(() => {
    if (parentkey !== "") setValue("parentKey", parentkey);
  }, [parentkey]);

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again later"
      );
    }
  };

  const addCB = (data) => {
    if (data.success) {
      return toast.success("Dining Added Successfully");
    } else {
      return toast.error(
        error ?? "Something went wrong. Please try again later."
      );
    }
  };

  const editCB = (data) => {
    if (data?.success) {
      toast.success("Edited Successfully");
    } else {
      return toast.error("Something went wrong. Please try again later");
    }
  };

  const handleClose = () => {
    toggler();
    reset();
    setCategoryName("");
    setDisplayName("");
    setViewRank("");
    setParentKey("Select Parent");
  };

  const onSubmit = (formValues) => {
    handleClose();

    formValues.viewRank = parseInt(formValues.viewRank);
    formValues.parentKey = parentkey;
    formValues.categoryName = formValues.categoryName.toLowerCase();
    formValues.displayName = formValues.displayName.toLowerCase();
    let { categoryName, displayName, viewRank, parentKey, ...data } =
      formValues;

    if (editData) {
      dispatch(editCategory({ ...formValues, id: editData._id, CB: editCB }));
    } else {
      dispatch(addNewCategory({ ...formValues, CB: addCB }));
    }
  };

  let existingViewRanks =
    categoryData?.result?.data?.map((obj) => obj.viewRank) ?? [];

  if (editData)
    existingViewRanks = existingViewRanks.filter(
      (val) => val != editData.viewRank
    );

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={handleClose}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Category Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="inroomdining"
                    name="categoryName"
                    {...register(`categoryName`, {
                      required: "Category Name is required",
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.categoryName && errors.categoryName.message}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Display Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="In-Room Dining"
                    name="displayName"
                    {...register(`displayName`, {
                      required: "Display Name is required",
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.displayName && errors.displayName.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"View Rank:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("viewRank", {
                      required: true,
                      min: { value: 1 },
                      validate: {
                        existing: (value) =>
                          !existingViewRanks.includes(parseInt(value)),
                      },
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.viewRank?.type == "min" &&
                      "View rank should be at least 1"}
                    {errors.viewRank?.type == "existing" &&
                      "This view rank already exists"}
                    {errors.viewRank?.type == "required" &&
                      "View rank is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"View Type:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="submenu"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{" Select Parent"}</Label>
                  <MyDropdown
                    title={parentkey}
                    setTitle={setParentKey}
                    data={categoryOptions.map((val) => ({
                      opt: val,
                      value: val,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default CategoryModal;

import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { useDispatch } from "react-redux";
import { getAllDepartments, getCategoryComponents } from "./categoriesApi";
import Essentials from "./MainCategories/Essentials";
import MainCategoryTabs from "./MainCategoryTabs";
import DiningCategory from "./MainCategories/DiningCategory";
import ServicesCategory from "./MainCategories/ServicesCategory";
import { EssentialsModal } from "./EssentialsModal";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { ServicesModal } from "./ServicesModal";
import { DiningModal } from "./DiningModal";
import { toast } from "react-toastify";

const Categories = () => {
  const dispatch = useDispatch();
  const { categoryComponentsData, isLoading, categoryComponentsDataLoading, error } = useSelector(categoriesSelector);
  const [activeTab, setActiveTab] = useState("");
  const [searchText, setSearchText] = useState("");
  const [headingData, setHeadingData] = useState([]);
  const [essentialsAdd, setEssentialsAdd] = useState(false);
  const [serviceAdd, setServiceAdd] = useState(false);
  const [diningAdd, setDiningAdd] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(true);

  useEffect(() => {
    dispatch(getCategoryComponents());
    dispatch(getAllDepartments());
  }, []);

  useEffect(() => {
    if(categoryComponentsData?.cateogry) {
      setHeadingData(categoryComponentsData.cateogry);
      if(activeTab == "") setActiveTab(categoryComponentsData.cateogry[0]?.displayName);
    }
  }, [categoryComponentsData]);

  useEffect(() => {
    if(error) toast.error(error);
  }, [error]);

  useEffect(() => {
    setSearchText("");
    setShowSearchInput(true);
  }, [activeTab]);

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleEssentialsAdd = () => !isLoading && setEssentialsAdd(!essentialsAdd);
  const handleServiceAdd = () => !isLoading && setServiceAdd(!serviceAdd);
  const handleDiningAdd = () => !isLoading && setDiningAdd(!diningAdd);

  const existingServicesViewRanks = JSON.parse(JSON.stringify(categoryComponentsData?.component?.Services?.map(obj => obj.viewRank) ?? []));
  const existingEssentialsViewRanks = JSON.parse(JSON.stringify(categoryComponentsData?.component?.Essentials?.map(obj => obj.viewRank) ?? []));

  return (
    <Fragment>
      {
        essentialsAdd ?
          <EssentialsModal
            isOpen={essentialsAdd}
            ModalTitle={"Add New Essential"}
            toggler={handleEssentialsAdd}
            existingViewRanks={existingEssentialsViewRanks}
          />
        : null
      }

      {
        serviceAdd ?
          <ServicesModal
            isOpen={serviceAdd}
            ModalTitle={"Add New Service"}
            toggler={handleServiceAdd}
            existingViewRanks={existingServicesViewRanks}
          />
        : null
      }

      {
        diningAdd ?
          <DiningModal
            isOpen={diningAdd}
            ModalTitle={"Add New Dining"}
            toggler={handleDiningAdd}
          />
        : null
      }

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Category Configuration"
            title="Category Configuration"
          />


            <div className="social-tab p-b-20">
              {
                showSearchInput ?
                <MySearchInput
                  handleSearch={handleSearch}
                  searchText={searchText}
                  setSearchText={setSearchText}
                /> : null
              }
            </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <div className="social-tab align-items-baseline pb-0 m-t-10">
                <MainCategoryTabs
                  mainCategories={headingData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                {activeTab == "Essentials" ? (
                  <Btn
                    attrBtn={{
                      className: "m-l-15 d-block",
                      color: "primary",
                      disabled: isLoading,
                      onClick: handleEssentialsAdd,
                    }}
                  >Add New Essential</Btn>
                ) : activeTab == "Services" ? (
                  <Btn
                    attrBtn={{
                      className: "m-l-15 m-r-10 d-block",
                      color: "primary",
                      disabled: isLoading,
                      onClick: handleServiceAdd,
                    }}
                  >Add New Service</Btn>
                ) : activeTab == "Dining" ? (
                  <div className="d-flex justify-content-end">
                    {/* <Btn
                      attrBtn={{
                        className: "m-l-15 d-block",
                        color: "primary",
                        disabled: isLoading,
                        onClick: handleDiningEdit,
                      }}
                    >
                      Edit
                    </Btn> */}
                    <Btn
                      attrBtn={{
                        className: "m-l-15 m-r-10 d-block",
                        color: "primary",
                        disabled: isLoading,
                        onClick: handleDiningAdd,
                      }}
                    >Add New Dining</Btn>
                  </div>
                ) : null}
              </div>

              <CardBody className="p-t-15 p-b-15">
                {
                  categoryComponentsDataLoading && activeTab == ""
                    ? <div><BarLoader /></div> : null
                }
                {activeTab == "Essentials" ? (
                  <Essentials searchText={searchText} />
                ) : activeTab == "Dining" ? (
                  <DiningCategory
                    searchText={searchText}
                    setShowSearchInput={setShowSearchInput}
                  />
                ) : activeTab == "Services" ? (
                  <ServicesCategory searchText={searchText} />
                ) : (!categoryComponentsDataLoading && !categoryComponentsData) ? (
                  <div className="d-flex justify-content-center"><h3>No Data Found</h3></div>
                ) : null }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Categories;

const { createSlice } = require("@reduxjs/toolkit");
const {
  getcategory,
  addNewCategory,
  editCategory,
  deleteCategory,
} = require("./api");

const initialState = {
  categoryData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const CategorySlice = createSlice({
  initialState,
  name: "categories",
  extraReducers: (builder) => {
    builder
      .addCase(getcategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getcategory.fulfilled, (state, action) => {
        console.log("category fulfilled:", action.payload);
        state.isLoading = false;
        state.categoryData = action.payload;
      })
      .addCase(getcategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload && payload.status;
      })

      // CASE FOR ADD ADMINUSER
      .addCase(addNewCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addNewCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload && payload.status;
      })

      // CASE FOR EDIT ADMIN USER
      .addCase(editCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR REMOVE ADMIN USER
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default CategorySlice.reducer;
export const { setStatus } = CategorySlice.actions;
export const CategorySelector = (state) => state.getcategory;

import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { DiningNavTab } from './DiningNavTab';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../../categoriesSlice';
import { TitleWithArrow } from '../../../../../../mycomponents/TitleWithArrow';
import { Btn, H4 } from '../../../../../../AbstractElements';
import { TextViewType } from '../../TextViewType';
import { WebViewComponent } from '../../WebViewComponent';
import { LinkEditModal } from './LinkEditModal';
import { IncrementComponent } from '../../IncrementComponent';
import { IncrementWithImageWithoutRequest } from '../../IncrementWithImageWithoutRequest';
import { DiningModal } from '../../DiningModal';
import BarLoader from '../../../../../../Layout/Loader/BarLoader';
import { ComponentModal } from '../../ComponentModal';
import { deleteCategoryComponent } from '../../categoriesApi';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

export const DiningCategoryDetails = ({ diningTabs, searchText, setShowSearchInput }) => {
   const dispatch = useDispatch();
   const { categoryComponentsData, isLoading, categoryComponentsDataLoading } = useSelector(categoriesSelector);
   const [currentNavItemId, setCurrentNavItemId] = useState(diningTabs?.[0]?._id ?? "");
   const [activeTab, setActiveTab] = useState(0);
   const [subActiveTab, setSubActiveTab] = useState(0);
   const [submenuActiveTab, setSubmenuActiveTab] = useState(0);
   const [nesting, setNesting] = useState(0);
   const [isOpen, setIsOpen] = useState(false);
   const [editItem, setEditItem] = useState(null);
   const [diningEdit, setDiningEdit] = useState(false);
   const [diningEditData, setDiningEditData] = useState(null);
   const [componentModalOpen, setComponentModalOpen] = useState(false);
   const [editComponentModalOpen, setEditComponentModalOpen] = useState(false);
   const [editData, setEditData] = useState(null);
   const [isEditMainComponent, setIsEditMainComponent] = useState(true);
   const [subComponentModalOpen, setSubComponentModalOpen] = useState(false);
   const [submenuComponentModalOpen, setSubmenuComponentModalOpen] = useState(false);

   useEffect(() => {
      setCurrentNavItemId(diningTabs?.[0]?._id ?? "");
   }, [searchText]);

   useEffect(() => {
      setActiveTab(0);
      setSubActiveTab(0);
      setSubmenuActiveTab(0);
      setNesting(0);
   }, [currentNavItemId]);

   useEffect(() => {
      setSubActiveTab(0);
      setSubmenuActiveTab(0);
   }, [activeTab]);

   useEffect(() => {
      if(nesting != 0) {
         setShowSearchInput(false);
         setIsEditMainComponent(false);
      } else {
         setShowSearchInput(true);
         setIsEditMainComponent(true);
      }
   }, [nesting]);

   let currentTabObj = diningTabs?.find(obj => obj._id == currentNavItemId);
   let currentLinkGoTo = currentTabObj?.goTo;
   let headings = [];
   if(currentLinkGoTo) headings = JSON.parse(JSON.stringify(categoryComponentsData?.component?.[`${currentLinkGoTo}`] ?? []));
   else headings = currentTabObj ? [currentTabObj] : [];
   headings?.forEach(obj => {
      obj.title = obj.name ?? obj.title;
   });

   let activeTabGoto = headings?.[activeTab]?.goTo;
   console.log("activeTabGoto", activeTabGoto);
   let subHeadingsAndData = [];
   if(activeTabGoto) subHeadingsAndData = JSON.parse(JSON.stringify(categoryComponentsData?.component?.[`${activeTabGoto}`] ?? []));
   else subHeadingsAndData = headings?.[activeTab] ? [headings?.[activeTab]] : [];
   subHeadingsAndData?.forEach((obj) => {
     obj.title = obj.name ?? obj.title;
   });
   let subActiveTabGoto = subHeadingsAndData?.[subActiveTab]?.goTo;
   console.log("subActiveTabGoto", subActiveTabGoto);

   let submenuHeadingsAndData = [];
   if (activeTabGoto)
     submenuHeadingsAndData = JSON.parse(
       JSON.stringify(
         categoryComponentsData?.component?.[`${subActiveTabGoto}`] ?? []
       )
     );
   else submenuHeadingsAndData = currentTabObj ? [currentTabObj] : [];
   submenuHeadingsAndData?.forEach((obj) => {
     obj.title = obj.name ?? obj.title;
   });
   console.log("submenuHeadingsAndData>>>>>>>>>>>>", submenuHeadingsAndData);

   const handleTitleClick = (index) => {
      setActiveTab(index);
      setNesting(1);
   }

   const handleSubTitleClick = (index) => {
      setSubActiveTab(index);
      setNesting(2);
   }

   const handleSubmenuTitleClick = (index) => {
     setSubmenuActiveTab(index);
     setNesting(3);
   };

   const handleBackClick = () => {
      setNesting(prev => prev - 1);
   }

   const handleEdit = (editData) => {
      setEditItem(editData);
      setIsOpen(true);
   }

   const handleToggle = () => setIsOpen(!isOpen);
   const handleDiningEdit = () => !isLoading && setDiningEdit(!diningEdit);
   const handleComponentModalToggle = () => setComponentModalOpen(!componentModalOpen);
   const handleEditComponentModalToggle = () => setEditComponentModalOpen(!editComponentModalOpen);
   const handleSubComponentModalToggle = () => setSubComponentModalOpen(!subComponentModalOpen);
   const handleSubmenuComponentModalToggle = () => setSubmenuComponentModalOpen(!submenuComponentModalOpen);

   const subViews = ["submenu", "submenuwithcart", "webView"];

   function deleteCB(data) {
      if(data.success) {
        toast.success("Deleted successfully");
      } else {
        toast.error("Something went wrong. Please try again later");
      }
   }

   function deleteComponent(id) {
      const conf = window.confirm("Are you sure that you want to delete this component ?");
      if(conf) {
         dispatch(deleteCategoryComponent({ CB: deleteCB, id }));
      }
   }

   function handleComponentEdit(data) {
      setEditData(data);
      setEditComponentModalOpen(true);
   }
   
   return (
      <Fragment>
         <Container fluid={true}>
            <LinkEditModal
               isOpen={isOpen}
               toggler={handleToggle}
               ModalTitle={"Edit link"}
               editData={editItem}
            />

            {diningEdit ? (
               <DiningModal
                  isOpen={diningEdit}
                  ModalTitle={"Edit Dining Details"}
                  toggler={handleDiningEdit}
                  editData={diningEditData}
                  setEditData={setDiningEditData}
               />
            ) : null}

            {
               componentModalOpen ?
               <ComponentModal
                  isOpen={componentModalOpen}
                  ModalTitle={"Add Component"}
                  toggler={handleComponentModalToggle}
                  isMainComponent={true}
                  currentTabData={currentTabObj}
               /> : null
            }

            {
               editComponentModalOpen ?
               <ComponentModal
                  isOpen={editComponentModalOpen}
                  ModalTitle={"Edit Component"}
                  toggler={handleEditComponentModalToggle}
                  isMainComponent={isEditMainComponent}
                  isEdit={true}
                  currentTabData={editData}
               /> : null
            }

            {
               subComponentModalOpen ?
               <ComponentModal
                  isOpen={subComponentModalOpen}
                  ModalTitle={"Add Sub Component"}
                  toggler={handleSubComponentModalToggle}
                  isMainComponent={false}
                  currentTabData={headings?.[activeTab]}
               /> : null
            }
            {
               submenuComponentModalOpen ?
               <ComponentModal
                  isOpen={submenuComponentModalOpen}
                  ModalTitle={"Add Submenu"}
                  toggler={handleSubmenuComponentModalToggle}
                  isMainComponent={false}
                  currentTabData={subHeadingsAndData?.[subActiveTab]}
               /> : null
            }

            <div className="email-wrap bookmark-wrap">
               <Row>
                  <Col xl="4" md="12" className="box-col-4 xl-30">
                     <div className="md-sidebar">
                        <Card>
                           <CardBody>
                              {
                                 diningTabs?.length ?
                                    <div className="email-app-sidebar left-bookmark custom-scrollbar">
                                       <DiningNavTab
                                          diningTabs={diningTabs}
                                          currentNavItemId={currentNavItemId}
                                          setCurrentNavItemId={setCurrentNavItemId}
                                          handleDiningEdit={handleDiningEdit}
                                          setDiningEditData={setDiningEditData}
                                       />
                                    </div>
                                 : <div>No Dining Found</div>
                              }
                           </CardBody>
                        </Card>
                     </div>
                  </Col>
                  {
                     isLoading || categoryComponentsDataLoading ? 
                        <Col xl="8" md="12" className="box-col-8 xl-70">
                           <Card className="email-body radius-left p-3 p-md-5">
                              <BarLoader />
                           </Card>
                        </Col>
                     : null
                  }
                  {
                     !isLoading && !categoryComponentsDataLoading && nesting == 0 ?
                        <Col xl="8" md="12" className="box-col-8 xl-70">
                           <Card className="email-body radius-left p-3 p-md-5">
                           <div className="d-flex justify-content-between m-0 p-0">
                              <H4 attrH4={{ className: "p-t-5 m-b-25" }}>
                                 {currentTabObj?.name ?? ""}
                              </H4>
                              {
                                 subViews.slice(0, 2).includes(currentTabObj?.viewType) ?
                                    <Btn
                                       attrBtn={{
                                          color: "primary",
                                          className: "m-0 m-b-15",
                                          onClick: handleComponentModalToggle,
                                          disabled: false
                                       }}
                                    >
                                       Add Component
                                    </Btn>
                                 : null
                              }
                           </div>
                           {
                              headings?.length ?
                                 headings?.map((obj, index) => {
                                    if(subViews.includes(obj.viewType)) {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <TitleWithArrow
                                                   heading={obj.title}
                                                   onClick={() => handleTitleClick(index, obj)}
                                                />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                             <i onClick={() => handleComponentEdit(headings?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(headings?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else if(obj?.viewType == "text") {
                                       return(
                                          <TextViewType key={index} data={obj} />
                                       );
                                    } else if(obj?.viewType == "increment") {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <IncrementComponent data={obj} />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(headings?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(headings?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else if(obj?.viewType == "incrementwithimagewithoutrequest") {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <IncrementWithImageWithoutRequest data={obj} />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(headings?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(headings?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else return null;
                                 })
                              : <div>No Data Found</div>
                           }
                           </Card>
                        </Col>
                     : null
                  }
                  {
                     !isLoading && !categoryComponentsDataLoading && nesting == 1 ?
                        <Col xl="8" md="12" className="box-col-8 xl-70">
                           <Card className="email-body radius-left p-3 p-md-5">
                              {
                                 activeTabGoto ? (
                                    <div className="d-flex justify-content-between m-0 p-0">
                                       <H4 attrH4={{ className: "p-t-5 m-b-25" }}>
                                          <i onClick={handleBackClick} className="fa fa-arrow-left m-r-10 p-0 cursor-pointer"></i>
                                          {headings?.[activeTab]?.title}
                                       </H4>
                                       <Btn
                                          attrBtn={{
                                             color: "primary",
                                             className: "m-0 m-b-15",
                                             onClick: handleSubComponentModalToggle,
                                             disabled: false
                                          }}
                                       >
                                          Add Sub Component
                                       </Btn>
                                    </div>
                                 ) : null
                              }
                              {
                                 subHeadingsAndData?.length ?
                                 subHeadingsAndData?.map((obj, index) => {
                                    if(obj?.viewType == "webView" && headings?.[activeTab]?.viewType == "webView") {
                                       return (
                                          <WebViewComponent
                                             key={index}
                                             webObj={headings?.[activeTab]}
                                             handleBackClick={handleBackClick}
                                             isLoading={isLoading}
                                             handleEdit={handleEdit}
                                          />
                                       );
                                    } else if(subViews.includes(obj?.viewType)) {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <TitleWithArrow
                                                   heading={obj.title || obj.name}
                                                   onClick={() => handleSubTitleClick(index, obj)}
                                                />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(subHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(subHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else if(obj?.viewType == "text") {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <TextViewType data={obj} />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(subHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(subHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else if(obj?.viewType == "increment") {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <IncrementComponent data={obj} />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(subHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(subHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else if(obj?.viewType == "incrementwithimagewithoutrequest") {
                                       return (
                                          <Row key={index}>
                                             <Col className="col-10">
                                                <IncrementWithImageWithoutRequest data={obj} />
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => handleComponentEdit(subHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                             </Col>
                                             <Col className="col-1 d-flex justify-content-end align-items-center">
                                                <i onClick={() => deleteComponent(subHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                             </Col>
                                          </Row>
                                       );
                                    } else return <div>Nothing matched</div>;
                                 }) : <div>No Data Found</div>
                              }
                           </Card>
                        </Col>
                     : null
                  }
                  {
                     !isLoading && !categoryComponentsDataLoading && nesting >= 2 ?
                     <Col xl="8" md="12" className="box-col-8 xl-70">
                        <Card className="email-body radius-left p-3 p-md-5">
                           {
                              subHeadingsAndData?.length  && subHeadingsAndData?.[subActiveTab].viewType == "webView" ?  (
                                 <WebViewComponent
                                    webObj={subHeadingsAndData?.[subActiveTab]}
                                    handleBackClick={handleBackClick}
                                    isLoading={isLoading}
                                    handleEdit={handleEdit}
                                 />
                              ): 
                                 <div className="d-flex justify-content-between m-0 p-0">
                                    <H4 attrH4={{ className: "p-t-5 m-b-25" }}>
                                       <i onClick={handleBackClick} className="fa fa-arrow-left m-r-10 p-0 cursor-pointer"></i>
                                       { subHeadingsAndData?.[subActiveTab]?.goTo || subHeadingsAndData?.[subActiveTab]?.title }
                                       {console.log("subHeadingsAndData?.[subActiveTab]?.name", subHeadingsAndData?.[subActiveTab])}
                                    </H4>
                                    <Btn
                                       attrBtn={{
                                          color: "primary",
                                          className: "m-0 m-b-15",
                                          onClick: handleSubComponentModalToggle,
                                          disabled: false
                                       }}
                                    >
                                       Add Submenu
                                    </Btn>
                                 </div>
                              
                           }
                           {console.log("submenuHeadingsAndData<<<<<<<<<<<<", submenuHeadingsAndData)}
                       
                           {
                              submenuHeadingsAndData?.length ?
                              submenuHeadingsAndData?.map((obj, index) => {
                                 if(obj?.viewType == "submenu"  && subHeadingsAndData?.[subActiveTab].viewType == "submenu") {
                                    {console.log("obj>>>>>>>", obj)}
                                    console.log("index", index);
                                    return (
                                       <Row key={index}>
                                          <Col className="col-10">
                                             <TitleWithArrow
                                                heading={obj.title || obj.name || obj.goTo}

                                                onClick={() => handleSubmenuTitleClick(index, obj)}
                                             />
                                          </Col>
                                          <Col className="col-1 d-flex justify-content-end align-items-center">
                                             <i onClick={() => handleComponentEdit(submenuHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                          </Col>
                                          <Col className="col-1 d-flex justify-content-end align-items-center">
                                             <i onClick={() => deleteComponent(submenuHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                          </Col>
                                       </Row>
                                    );
                                 }
                                 else if(obj?.viewType == "increment") {
                                    return (
                                       <Row key={index}>
                                          <Col className="col-10">
                                             <IncrementComponent data={obj} />
                                          </Col>
                                          <Col className="col-1 d-flex justify-content-end align-items-center">
                                             <i onClick={() => handleComponentEdit(submenuHeadingsAndData?.[index])} class="icon-pencil-alt fs-4 cursor-pointer"></i>
                                          </Col>
                                          <Col className="col-1 d-flex justify-content-end align-items-center">
                                             <i onClick={() => deleteComponent(submenuHeadingsAndData?.[index]?._id)} className="icon-trash fs-4 cursor-pointer"></i>
                                          </Col>
                                       </Row>
                                    );
                                 }
                                 
                                 
                              }) : null
                           }
                        </Card>
                     </Col> : null
                  }
               </Row>
            </div>
         </Container>
      </Fragment>
   );
}
import { configureStore } from "@reduxjs/toolkit";
import amenitiesSlice from "../Pages/SidebarMenuPages/HotelConfig/Amenities/amenitiesSlice";
import roomTypesSlice from "../Pages/SidebarMenuPages/HotelConfig/RoomTypes/roomTypesSlice";
import ratePlansSlice from "../Pages/SidebarMenuPages/HotelConfig/RatePlans/ratePlansSlice";
import roomsSlice from "../Pages/SidebarMenuPages/HotelConfig/Rooms/roomsSlice";
import packagesSlice from "../Pages/SidebarMenuPages/HotelConfig/Packages/packagesSlice";
import exploreDetailsSlice from "../Pages/SidebarMenuPages/HotelConfig/ExploreDetails/exploreDetailsSlice";
import diningSlice from "../Pages/SidebarMenuPages/HotelConfig/Dining/diningSlice";
import loginSlice from "../Auth/Tabs/LoginTab/loginSlice";
import hotelConfigSlice from "../Pages/SidebarMenuPages/HotelConfig/HotelConfig/hotelConfigSlice";
import lightCategorySlice from "../Pages/SidebarMenuPages/DeviceConfig/LightCategory/lightCategorySlice";
import appVerisonsSlice from "../Pages/SidebarMenuPages/AppVersions/appVerisonsSlice";
import loyaltyConfigSlice from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyConfig/loyaltyConfigSlice";
import masterUsersSlice from "../Pages/SidebarMenuPages/MasterUsers/masterUsersSlice";
import frontDeskPagesSlice from "../Pages/SidebarMenuPages/MasterUsers/frontDeskPagesSlice";
import allDeviceslice from "../Pages/SidebarMenuPages/DeviceConfig/AllDevices/allDeviceslice";
import loyaltyUnsettledSlice from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyUnsettled/loyaltyUnsettledSlice";
import loyaltyReportSlice from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyReports/loyaltyReportSlice";
import categoriesSlice from "../Pages/SidebarMenuPages/InRoomServices/Categories/categoriesSlice";
import hotelStaffSlice from "../Pages/SidebarMenuPages/HotelStaff/Slice/hotelStaffSlice";
import meetingRoomSlice from "../Pages/SidebarMenuPages/Meeting/MeetingRoom/meetingRoomSlice";
import meetingCategorySlice from "../Pages/SidebarMenuPages/Meeting/MeetingCategory/meetingCategorySlice";
import componentDataSelector from "../Pages/SidebarMenuPages/InRoomServices/Component/componentSlice";
import CategorySlice from "../Pages/SidebarMenuPages/InRoomServices/Category/CategorySlice";

export const store = configureStore({
  reducer: {
    fetchAmenities: amenitiesSlice,
    fetchRoomTypes: roomTypesSlice,
    fetchRatePlans: ratePlansSlice,
    fetchRooms: roomsSlice,
    fetchPackages: packagesSlice,
    fetchAppVersions: appVerisonsSlice,
    fetchExploreDetails: exploreDetailsSlice,
    fetchDining: diningSlice,
    fetchAdmin: loginSlice,
    fetchHotelConfig: hotelConfigSlice,
    fetchLightCategory: lightCategorySlice,
    allDevicesOfHotel: allDeviceslice,
    fetchLoyaltyConfig: loyaltyConfigSlice,
    fetchRatePlansSourceCodesAndFloors: loyaltyConfigSlice,
    getadminuser: masterUsersSlice,
    frontDeskData: frontDeskPagesSlice,
    fetchLoyaltyUnsettled: loyaltyUnsettledSlice,
    loyaltyReports: loyaltyReportSlice,
    categories: categoriesSlice, 
    hotelStaff: hotelStaffSlice,
    fetchMeetingRoom: meetingRoomSlice,
    fetchMeetingCategories: meetingCategorySlice,
    getcomponent: componentDataSelector,
    getcategory: CategorySlice,
  },
});

import { Col, Nav, NavItem, Row } from "reactstrap";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { deleteCategoryComponent } from "../../categoriesApi";
import { toast } from "react-toastify";

export const DiningNavTab = ({ diningTabs, currentNavItemId, setCurrentNavItemId, setDiningEditData, handleDiningEdit }) => {
  const dispatch = useDispatch();

  function deleteCB(data) {
    if(data.success) {
      toast.success("Deleted successfully");
    } else {
      toast.error("Something went wrong. Please try again later");
    }
  }
  
  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure that you want to delete this ?");
    if(conf) {
      dispatch(deleteCategoryComponent({ CB: deleteCB, id }));
    }
  };

  const handleEdit = (obj) => {
    setDiningEditData(obj);
    handleDiningEdit();
  }
  
  return (
    <Fragment>
      <Nav className="email-wrap main-menu mt-3" role="tablist">
        {diningTabs.map((obj, index) =>
          (<NavItem key={index}>
            <Row>
              <Col className="col-9 mr-2">
                <Link
                  to={""}
                  onClick={(e) => {
                      e.preventDefault();
                      setCurrentNavItemId(obj._id);
                  }}
                  style={currentNavItemId === obj._id ? { backgroundColor: "#d762e71a" } : {}}
                >
                  <span className="title">{obj.name}</span>
                  {
                    obj.viewType == "submenuwithcart" || obj.viewType == "submenu" || obj.viewType == "webView" || obj.viewType == "text" || obj.viewType == "textbox" ? (
                      <span>
                        <i className="fa fa-angle-right"></i>
                      </span>
                    ) : null
                  }
                </Link>
              </Col>
              <Col className="col-2 d-flex justify-content-center align-items-center pb-1">
                <i onClick={() => handleEdit(obj)} class="icon-pencil-alt fs-5 cursor-pointer"></i>
              </Col>
              <Col onClick={() => handleDelete(obj._id)} className="col-1 d-flex justify-content-center align-items-center pb-1">
                <i className="icon-trash fs-5 cursor-pointer"></i>
              </Col>
            </Row>
          </NavItem>)
        )}
      </Nav>
    </Fragment>
  );
};
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../categoriesSlice';
import { ServicesDetails } from './DetailsComponents/ServicesDetails';

const ServicesCategory = ({ searchText }) => {
  const { categoryComponentsData } = useSelector(categoriesSelector);
  let servicesData = JSON.parse(
    JSON.stringify(categoryComponentsData?.component?.Services ?? [])
  );

  // Filter services data based on search text, ensuring obj and obj.name exist
  servicesData = servicesData.filter(
    (obj) =>
      obj &&
      obj.name &&
      obj.name.toLowerCase().includes(searchText.toLowerCase())
  );

  console.log("servicesData", servicesData);

  return (
    <Fragment>
      <ServicesDetails servicesData={servicesData} searchText={searchText} />
    </Fragment>
  )
}

export default ServicesCategory;
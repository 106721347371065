import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import ComponentModal from "./ComponentModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { componentDataSelector } from "./componentSlice";
import { toast } from "react-toastify";
import { getcomponent, removecomponent } from "./api";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { isVisible } from "@testing-library/user-event/dist/cjs/utils/index.js";

const Component = () => {
  const dispatch = useDispatch();
  const { componentData, isLoading, status } = useSelector(
    componentDataSelector
  );
  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isSectionVisible, setIsSectionVisible] = useState(true);

  useEffect(() => {
    dispatch(getcomponent());
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handlePerPageRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    if (conf) {
      dispatch(removecomponent({ id, CB: functionCB }));
    }
  };

  const handleToggleVisibility = async (_id, currentVisibility, val) => {
    try {
      // Send request to update visibility in the database
      console.log(
        "handleToggleVisibility >> _id, currentVisibility, val",
        _id,
        !currentVisibility,
        val
      );

      const response = await AXIOS_RESERVATION.post(`/updateComponent`, {
        _id,
        isVisible: !currentVisibility,
      });
      console.log("Update response:", response);
      dispatch(getcomponent());
    } catch (error) {
      console.error(
        "Error updating visibility:",
        error.response?.data || error.message
      );
    }
  };

  // function to send the updated status to the backend
  const updateVisibilityInBackend = async (updatedVisibility) => {
    try {
      // Prepare data for batch update
      const response = await AXIOS_RESERVATION.post(
        "updateComponentVisibility",
        { updatedVisibility }
      );

      console.log("Data to be sent:", updatedVisibility);
      if (response.status === 200) {
        console.log("Successfully updated visibility:", response.data);
      } else {
        console.error("Failed to update visibility:", response.statusText);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

const ComponentRData = componentData?.result?.data
  ?.filter((val) => {
    const searchTextLower = searchText.toLowerCase();
    return (
      val?.viewType?.toLowerCase().includes(searchTextLower) ||
      val?.requiredKey?.some((key) =>
        key.toLowerCase().includes(searchTextLower)
      ) // Check if any key matches
    );
  })
  .map((val, i) => {
    return {
      index: i + 1,
      type: val.viewType || "Default Type",
      parent_key: val.requiredKey.join(", ") || "No Key",
      rank: val.rank || 0,
      isVisible: (
        <input
          type="checkbox"
          checked={val.isVisible}
          onChange={() => handleToggleVisibility(val._id, val.isVisible, val)} // Toggle visibility on change
        />
      ),
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEdit(val),
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: () => handleDelete(val._id),
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    };
  });

  const ComponentColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Type</H6>,
      selector: (row) => row.type,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Parent Key</H6>,
      selector: (row) => row.parent_key,
      sortable: true,
      center: true,
      wrap: true,
    },
    // {
    //   name: <H6>Rank</H6>,
    //   selector: (row) => row.rank,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    {
      name: <H6>Hide/show</H6>,
      selector: (row) => row.isVisible,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <ComponentModal
        isOpen={isAdd}
        ModalTitle={"Add Component"}
        toggler={handleAdd}
      />
      {isEdit && editData ? (
        <ComponentModal
          isOpen={isEdit}
          ModalTitle={"Edit Component"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Component Configuration"
            title="Component Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            {/* <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                disabled: isLoading,
                onClick: handleAdd,
              }}
            >
              Add Component
            </Btn> */}
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>COMPONENT DATA</H4>
                </div>
              </Col>

              <CardBody>
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={ComponentRData}
                    columns={ComponentColumns}
                    responsive={true}
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerPageRowsChange}
                    onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Component;

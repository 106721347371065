import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";
import { addnewcomponent, editcomponent, } from "./api";

const ComponentModal = ({ isOpen, ModalTitle, toggler, editData }) => {

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    if (editData) {
      dispatch(editcomponent({ ...data, CB: functionCB }));
    } else {
      dispatch(addnewcomponent({ ...data, CB: functionCB }));
    }
    handleClose();
  };

  const handleClose = () => {
    toggler();
    reset();
  };
  
  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={handleClose}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Component Type:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="increment"
                    name="type"
                    {...register(`type`, {
                      required: "Component Type is required",
                    })}
                    disabled={editData ? true : false}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.type && errors.type.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Required Keys:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="write keys with commaseperated"
                    name="requiredKey"
                    {...register(`requiredKey`, {
                      required: "Required Key is required",
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.requiredKey && errors.requiredKey.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default ComponentModal;
